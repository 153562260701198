export default {
    init() {
        // JavaScript to be fired on all pages

        // // Jquery-match-height
        // $(function() {
        //     $('.example').matchHeight();
        // });

        // // Slick slider example
        // $('.slick__slider').slick({
        //     dots: true,
        //     infinite: true,
        //     speed: 300,
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //     responsive: [
        //         {
        //             breakpoint: 1024,
        //             settings: {

        //             },
        //         },
        //     ],
        // });

        // $(document).ready(function() {
        //     $(".fancybox").fancybox();
        // });

        $('.btn-next').append('<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z"></path></svg>');
        $('.btn-prev').append('<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-left" class="svg-inline--fa fa-angle-left fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M4.2 247.5L151 99.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17L69.3 256l118.5 119.7c4.7 4.7 4.7 12.3 0 17L168 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 264.5c-4.7-4.7-4.7-12.3 0-17z"></path></svg>');

        if ($(window).width() > 991) {
            var $el, leftPos, newWidth;

            /* Add Magic Line markup via JavaScript, because it ain't gonna work without */
            $('.nav').append('<li id="active__line"></li>');

            /* Cache it */
            var $activeLine = $('#active__line');

            setTimeout(function() {
                $activeLine
                    .width($('.nav .active a').width())
                    .css('left', $('.nav .active').position().left + 16)
                    .data('origLeft', $activeLine.position().left)
                    .data('origWidth', $activeLine.width());
            }, 1000);

            $('.nav li')
                .find('a')
                .hover(
                    function() {
                        $el = $(this);
                        leftPos = $el.parent().position().left + 16;
                        newWidth = $el.width();

                        console.log($el);

                        $activeLine.stop().animate({
                            left: leftPos,
                            width: newWidth,
                        });
                    },
                    function() {
                        $activeLine.stop().animate({
                            left: $activeLine.data('origLeft'),
                            width: $activeLine.data('origWidth'),
                        });
                    }
                );
        }

        $(window).scroll(function() {
            var scroll = $(window).scrollTop();
            if (scroll > 100) {
                $('.header').addClass('header--fix');
            }
            else {
                $('.header').removeClass('header--fix');
            }
        });

        // Slick slider example
        $('.banner__slider').slick({
            autoplay:true,
            autoplaySpeed: 3000,
            draggable: true,
            arrows: false,
            dots: false,
            fade: true,
            speed: 900,
            infinite: true,
        });

        $('.menu__search').click( function() {
            $('.header__search__bar').addClass('open__search');
        });

        $('.header__search__form__close').click(function() {
            $('.header__search__bar').removeClass('open__search');
        });

        $(function() {
            $('.block__item').matchHeight();
            $('.contact__item').matchHeight();
            $('.team__item__content').matchHeight();
        });

        $('body').on('click', '.form__material_wrapper .gfield', function() {
            $(this).addClass('form__label-selected');
        });

        $('body').on('focus', '.form__material_wrapper .gfield input', function() {
            $(this).parent().parent().addClass('form__label-selected');
        });

        $('body').on('blur', '.form__material_wrapper .gfield input', function() {
            if ($(this).val()) {
                $(this).parent().parent().addClass('form__label-selected');
            } else {
                $(this).parent().parent().removeClass('form__label-selected');
            }
        });
        $('.form__material_wrapper .gfield input').each(function () {
            var text_value = $(this).val();
            if (text_value !== '') {
                $(this).parent().parent().addClass('form__label-selected');
            }
        });

        $('body').on('click', '.form__material_wrapper .gfield textarea', function() {
            $(this).parent().parent().addClass('form__label-selected');
        });

        $('body').on('focus', '.form__material_wrapper .gfield textarea', function() {
            $(this).parent().parent().addClass('form__label-selected');
        });

        $('body').on('blur', '.form__material_wrapper .gfield textarea', function() {
            if ($(this).val()) {
                $(this).parent().parent().addClass('form__label-selected');
            } else {
                $(this).parent().parent().removeClass('form__label-selected');
            }
        });

        $('.form__material_wrapper .gfield textarea').each(function () {
            var text_value = $(this).val();
            if (text_value !== '') {
                $(this).parent().parent().addClass('form__label-selected');
            }
        });

        // var $field = $('.ginput_container_fileupload').find('input[type=file]');

        // $field.bind('change',function() {
        //     if($field.val() != '') {
        //         $('.file__input').text($field.val().split('\\').pop());
        //     } else {
        //         $('.file__input').text('Upload je CV');
        //     }
        // });

        // $('.ginput_container_fileupload').each(function () {
        //     var $label = $(this).parent('li').find('.gfield_label');
        //     $label.append('<span class="file__input">Upload je CV</span>');
        // });

        jQuery(document).on('gform_post_render', function(event, form_id){
            // code to trigger on AJAX form render
            $('input,textarea', $('#gform_' + form_id)).each(function() {
                if ($(this).val() !== '') {
                    $(this).parent().parent().addClass('form__label-selected');
                }
            });
        });

        $(document).on('sf:ajaxfinish', '.searchandfilter', function(){
            $('.block__item__content').matchHeight();
            $('.block__item__title').matchHeight();
            $('.block__item__text').matchHeight();
        });

        setTimeout(function() {
            if (window.location.search.indexOf('filter') > -1) {
                $('html, body').animate({
                    scrollTop: $('.archive__filter').offset().top - 100,
                }, 10);
            }
        }, 1000);
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
