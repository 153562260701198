export default {
    init() {
        // JavaScript to be fired on the home page

        $('.home__team__slider').slick({
            dots: false,
            infinite: true,
            speed: 300,
            fade: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: '.home__team__slider__arrow.prev',
            nextArrow: '.home__team__slider__arrow.next',
        });
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
